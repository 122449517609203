import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import urljoin from "url-join";
import PersonalBlog from "../containers/home";
import SEO from "../components/seo";
import PostDetails from "../components/post-details/post-details";
import Sidebar2 from "../containers/sidebar2";
import Iframely from "../components/iframely";

import {
	FacebookShareButton,
	TwitterShareButton,
	PinterestShareButton,
	RedditShareButton,
} from "react-share";
import {
	IoLogoFacebook,
	IoLogoTwitter,
	IoLogoPinterest,
	IoLogoReddit,
} from "react-icons/io";
import {
	BlogPostDetailsWrapper,
	RelatedPostWrapper,
	RelatedPostItems,
	RelatedPostTitle,
	RelatedPostItem,
	BlogPostFooter,
	PostShare,
	PostTags,
	BlogPostComment,
	BlogDetailsContent,
	// BlogPostCatch,
	BlogPostsWrapper,
} from "../templates/templates.style";

const HomePage = (props: any) => {
	// console.log("title: " + props.data.wpPage);
	const siteUrl = props.data.site.siteMetadata.siteUrl;
	const description = props.data.site.siteMetadata.description;
	const { title, date, modified, featuredImage, content, uri } =
		props.data.wpPage;
	// console.log("siteUrl: " + siteUrl);
	// console.log("uri: " + uri);
	const coverUrl = featuredImage.node.localFile.publicURL;
	const shareUrl = urljoin(siteUrl, uri);
	const disqusConfig = {
		shortname: process.env.GATSBY_DISQUS_NAME,
		config: { identifier: uri, title },
	};

	return (
		<Layout>
			<Iframely />
			<SEO
				title="【厳選おすすめ36社比較】宅配クリーニング目的別おすすめランキング！安い店や保管付きも！"
				description={description}
			/>
			{/* <PersonalBlog /> */}
			<BlogPostsWrapper>
				<BlogPostDetailsWrapper>
					<BlogDetailsContent>
						<PostDetails
							// catchText={catchText.text}
							title={title}
							date={date}
							tags={null}
							modified={modified}
							preview={
								featuredImage == null
									? null
									: featuredImage.node.localFile
											.childImageSharp.gatsbyImageData
							}
							description={content}
						/>

						{/* <BlogPostFooter>
						<PostShare>
							<span>Share This:</span>
							<FacebookShareButton url={shareUrl} quote={excerpt}>
								<IoLogoFacebook />
							</FacebookShareButton>
							<TwitterShareButton url={shareUrl} title={title}>
								<IoLogoTwitter />
							</TwitterShareButton>
							<PinterestShareButton
								url={shareUrl}
								media={urljoin(siteUrl, coverUrl)}
							>
								<IoLogoPinterest />
							</PinterestShareButton>
							<RedditShareButton url={shareUrl} title={title}>
								<IoLogoReddit />
							</RedditShareButton>
						</PostShare>
					</BlogPostFooter> */}

						<BlogPostComment>
							{/* <DiscussionEmbed {...disqusConfig} /> */}
						</BlogPostComment>
					</BlogDetailsContent>
				</BlogPostDetailsWrapper>
				<Sidebar2 />
			</BlogPostsWrapper>
		</Layout>
	);
};

export default HomePage;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
		wpPage(slug: { eq: "top" }) {
			id
			# excerpt
			content
			uri
			title
			# catchText {
			# 	text
			# }
			date(formatString: "YYYY年MM月DD日")
			modified(formatString: "YYYY年MM月DD日")
			# tags {
			# 	nodes {
			# 		name
			# 	}
			# }
			featuredImage {
				node {
					altText
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
			}
		}
	}
`;
